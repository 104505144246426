
* {
  margin: 0;
  font-family: 'Archivo Black', sans-serif;
  box-sizing: border-box;
}

h1 {
  font-size: 120px;
  font-weight: 400;
  text-decoration: underline;
  margin: 20px 0;
}

nav {
  height: 100px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
  width: 100%;
  margin: 0;
  top: 0;
  background: #d82265;
  z-index: 2;
}

a {
  display: flex;
  margin: 5px;
  justify-content: right;
  align-items: right;
  padding: 10px;
}

nav a img {
  width: 50px;
  height: 50px;
}

header {
  background: #d82265;
  color: white;
  width: 100%;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  
}

.header-title {
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  padding: 50px;
  margin: 100px 0 0 0;
  height:300px;
  /* background-color: teal; */
}

@media (prefers-reduced-motion: no-preference) {
  .taco {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}  

.header-title .taco {
  background-image: url('./images/Taco-01.svg');
  background-repeat: no-repeat;
  height: 400px;
  width: 400px;
  margin-left: 50px;
  /* right: 20px;
  top: 20px; */
  /* position: absolute; */
}

.header-title h1 {
  font-size: 150px;
  font-weight: 400;
  margin: 0 0 0 9px;
  text-decoration: none;
}

.about {
  margin: 150px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 50px;
}

/* 
.about-div {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
} */

.about img {
  width: 500px;
  border: 10px solid black;
}

.about h1 {
  text-align: center;
}

p {
  font-family: 'Montserrat Alternates', sans-serif;
}

.about-paragraph {
  width: 40vw;
  font-size: 28px;
  font-family: 'Montserrat Alternates', sans-serif;
}

.skills-container {
  height: 1050px;
  background-color: #ffde03;
  align-items: baseline;
  background-image: url('./images/toolBelt-01.svg');
  background-repeat: no-repeat;
  background-position: bottom;
  display: flex;
}

.header-tagline {
  padding-left:  50px;
  background: #d82265;
  padding-bottom: 40px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.header-tagline h2 {
  margin:  0 0 0 20px;
  font-size: 60px;
  font-weight: 400;
}

.skills-div {
  /* padding-top: 40px; */
  margin-left: 100px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
}
.icons {
  width: 600px;
  background-color: black;
  border-radius: 10px;
  color: #ffde03;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  font-size: 120px;
  padding: 20px 0;
}

i {
  margin: 5px 30px;
}

.work {
  padding: 100px;
  text-align: center;
}

.work-container {
  display: flex;
  justify-content: space-between;
  margin-top: 100px ;
}

/* .work a  {
  width: 900px;
  height: 800px;
  border-radius: 20px;
  padding: 15px;
} */

.work-img-div {
  width: 1200px;
  display: flex;
  justify-content: center;
  border: 5px solid black;
  border-radius: 20px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  margin: 20px
}

.work-img {
  /* width: 900px; */
  max-width: 100%;
  /* height: 100%; */
  border-radius: 20px;
  padding: 15px;
}

.work-desc-div {
  width: 25vw;
  margin: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
}

.work h2 {
  font-size: 50px;
  padding: 15px 0;
}

.work h3 {
  font-size: 40px;
  padding: 15px 0;
}

.work p {
  font-size: 24px;
}

button {
  margin: 50px 0;
  background-color: black;
  border: none;  
  position: relative;
  border-radius: 5px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
}

button a {
  text-decoration: none;
  color: white;
  font-family: 'Archivo Black', sans-serif;
  font-size: 24px;
}

.about p {
  margin: 20px;
  text-indent: 50px;
  text-align: left;
}

.contact {
  background: #d82265;
  display: flex;
  justify-content: center;
  align-items: center;
  
}

.contact-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  justify-content: center;;
}

.contact-info a {
  text-decoration: none;
  color: white;
  margin: 0;
}

.contact-info h3 {
  font-family: 'Montserrat Alternates', sans-serif;
  margin: 5px;
}

.copyright {
  text-align: center;
  background-color: #d82265;
  font-size: 12px;
  padding: 5px;
}

/* less than 1500px */

@media (max-width: 1500px) {

h1 {
  font-size: 100px;
}

.header-title h1 {
  font-size: 120px;
}

.header-tagline h2 {
  font-size: 48px;
}

.header-title .taco {
  width: 350px;
  height: 350px;
}

.work {
  padding: 80px;
}

.work-img-div {
  width: 100%;
  height: 100%;
  /* background-color: lightgreen; */
}

.work-container {
  margin-top: 80px;
  align-items: center;
}

.about {
  margin: 60px;
}

.about p {
  font-size: 24px;
}

.about-paragraph {
  width: 100vw;
  margin: 30px;
}

.about img {
  width: 400px;
}
}

/* Less than 1200px */

@media (max-width: 1200px)  {

  .work {
    padding: 60px;
  }

  .work-container {
    margin-top: 50px;
  }

  .work-img-div {
    width: 100%;
    height: 100%;
    /* background: lightblue; */
  }

  .work h2 {
    font-size: 42px;
  }

  .work h3 {
    font-size: 32px;
  }

  .work p {
    font-size: 20px;
  }

  .skills-container {
    height: 950px;
  }

  .icons {
    width: 500px;
    font-size: 90px;
  }


}


/* Less than 900px */


@media only screen 
   and (max-width : 768px) 
   and (max-height : 1024px) 
  {
  nav {
    height: 80px;
  }

  h1 {
    font-size: 80px;
  }

  .header-title {
    padding-bottom: 20px;
  }

  .header-title h1 {
    font-size: 80px;
  }

  .header-tagline h2 {
    font-size: 36px;
    margin: 0 0 0 10px;
  }

  .header-title .taco{
    height: 275px;
    width: 275px;
    margin: 25px;
  }

  /* .work-img-div {
    background-color: lightpink;
    padding: -10px;
  } */

  .work-img {
    padding: 8px;
  }

  .work-container {
    flex-direction: column;
    margin-top: 10px;
  }

  .switch {
    flex-direction: column-reverse;
  }

  .work-desc-div {
    flex-direction: column;
    width: 80vw;
    justify-content: space-between;

  }

  .work-desc-div p {
    font-size: 28px;
  }

  .work h2 {
    display: none;
  }

  .work h3 {
    text-decoration: underline;
  }


  .work-skills {
    margin-top: 15px;
  }

  .work-skills p {
    font-size: 20px;
    font-weight: 900;
  }

  button {
    height: 70px;
  }

  .about {
    flex-direction: column;
  }

  .about img {
    width: 25vw;
  }

  .about p {
    font-size: 22px;
  }
  
  .about-paragraph {
    width: 75vw;
    margin: 30px;
  }

  .work a{
    padding: 0;
  }

}






@media (max-width: 600px) {
body {
  max-width: 600px;
}


  nav a img {
    height: 40px;
    width: 40px;
  } 

  .header-title {
    flex-direction: column-reverse;
    justify-content: center;
  }
  
  .header-title .taco {
    animation: none;
    min-width: 250px;
    min-height: 250px;
  }

  

  .work-img-div {
    background-color: lightslategray;
  }

}

